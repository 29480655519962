.about {
  background-color: transparent;
  font-size: 1.1em;
}

.profile {
  max-width: 18em;
}

.my-details {
  background-color: rgba(255, 255, 255, 0.747);
}

.font-details {
  font-size: 3em;
  font-weight: 500;
  color: #231d5af8;
}

.custom-bold8 {
  font-weight: 500;
}

.boldest {
  font-weight: 900;
}
