.contact-jumbotron {
  background-image: url("../../assets/img/background/background.webp");
  background-size: cover;
  background-repeat: no-repeat;
}

#youtube-btn {
  border-color: #f83f37;
  color: #f83f37;
}
#youtube-btn:hover {
  background-color: #f83f37;
  color: #ffffff;
}

.calendly-btn {
  border-color: rgb(0, 107, 255);
  color: rgb(0, 107, 255);
}
.calendly-btn:hover {
  background-color: rgb(0, 107, 255);
  color: #ffffff;
}
